import React from 'react'
import AlairConfImagesTwentyOne from '../../../components/AlairConfImagesTwentyOne'
import H1 from '../../../components/H1'
import Layout from '../../../components/Layout'

const acp2021 = () => (
  <Layout>
    <H1 title="ALAIR Conference Photos 2021" />
    <AlairConfImagesTwentyOne />
  </Layout>
)
export default acp2021
